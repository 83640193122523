import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import LandingPage from './components/LandingPage';
import AuthPage from './components/AuthPage'; // Import AuthPage

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Landing Page Route */}
        <Route path="/" element={<LandingPage />} />

        {/* Auth Page Route */}
        <Route path="/auth" element={<AuthPage />} />

        {/* Login Route */}
        <Route path="/login" element={<Login />} />

        {/* Dashboard Route */}
        <Route
          path="/dashboard"
          element={
            localStorage.getItem('access_token') ? (
              <Dashboard /> // Render the Dashboard component
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        {/* Redirect from any unknown routes to landing page */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
