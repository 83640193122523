import React, { useEffect, useState } from 'react';
import RepositoryManager from './RepositoryManager';
import TaskManager from './TaskManager';
import PRManager from './PRManager';
import PRButtons from './PRButtons'; // Import PRButtons
import axios from 'axios';

const getBaseUrl = () => {
  switch (process.env.NODE_ENV) {
    case 'production':
      return process.env.REACT_APP_API_BASE_URL;
    case 'development':
    default:
      return 'http://localhost:5000';
  }
};

axios.defaults.baseURL = getBaseUrl();

const Dashboard = () => {
  const [token, setToken] = useState('');
  const [jiraToken, setJiraToken] = useState(''); // State to store Jira token
  const [selectedRepo, setSelectedRepo] = useState('');
  const [taskManagerEnabled, setTaskManagerEnabled] = useState(false);
  const [prManagerEnabled, setPrManagerEnabled] = useState(false); // Initial state shows PRManager

  // Function to extract tokens from URL after OAuth login
  const extractTokenFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    const accessToken = params.get('access_token');
    const githubToken = params.get('github_token');
    const jiraTokenFromUrl = params.get('jira_token'); // Extract Jira token from URL if present

    // If tokens exist, store them in localStorage
    if (accessToken && githubToken) {
      setToken(accessToken);
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('github_token', githubToken);

      // Check if Jira token is available
      if (jiraTokenFromUrl) {
        setJiraToken(jiraTokenFromUrl);
        localStorage.setItem('jira_token', jiraTokenFromUrl);
      }

      // Clean URL by removing query parameters
      window.history.replaceState({}, document.title, '/dashboard');
    }
  };

  // Fetch token from localStorage or extract from URL on mount
  useEffect(() => {
    const storedToken = localStorage.getItem('access_token');
    const storedJiraToken = localStorage.getItem('jira_token'); // Check Jira token from localStorage
    if (storedToken) {
      console.log('Token found in localStorage:', storedToken);
      setToken(storedToken);
    }
    if (storedJiraToken) {
      console.log('Jira token found in localStorage:', storedJiraToken);
      setJiraToken(storedJiraToken);
    } else {
      console.log('No token found in localStorage, extracting from URL...');
      extractTokenFromUrl();
    }
  }, []);

  // Function to enable TaskManager and disable PRManager
  const handleTaskManagerEnable = () => {
    setTaskManagerEnabled(true);
    setPrManagerEnabled(false); // Hide PRManager
  };

  // Function to enable PRManager and disable TaskManager
  const handlePrManagerEnable = () => {
    setTaskManagerEnabled(false);
    setPrManagerEnabled(true); // Show PRManager
  };

  // Handle Jira login
  const handleJiraLogin = () => {
    window.location.href = `${getBaseUrl()}/api/auth/jira`;
  };

  return (
    <div>
      <h1>Dashboard</h1>

      {/* Show Jira login button if not logged into Jira */}
      {/* {!jiraToken ? (
        <div>
          <p>You are logged in with GitHub. Would you like to connect to Jira?</p>
          <button onClick={handleJiraLogin}>Login with Jira</button>
        </div>
      ) : (
        <p>You are connected to Jira!</p>
      )} */}

      {/* Repository Manager to select the repository */}
      <RepositoryManager token={token} onSelectRepo={setSelectedRepo} />

      {selectedRepo && (
        <>
          {/* Render PRButtons to toggle between TaskManager and PRManager */}
          <PRButtons
            showTaskManager={handleTaskManagerEnable}
            showPRManager={handlePrManagerEnable}
          />

          {/* Conditional rendering based on button click */}
          {prManagerEnabled && (
            <PRManager
              token={token}
              selectedRepo={selectedRepo}
            />
          )}

          {taskManagerEnabled && (
            <TaskManager
              token={token}
              selectedRepo={selectedRepo}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Dashboard;
