import React from 'react';

const PricingPage = () => {
    // Function to open the Stripe Customer Portal
    const handleOpenCustomerPortal = () => {
        // Redirige directement vers le portail client Stripe
        window.location.href = 'https://billing.stripe.com/p/login/eVa9BMa1edoa3TO8ww';
    };

    return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
            <h1>Choose Your Subscription</h1>
            {/* Stripe Pricing Table */}
            <stripe-pricing-table
                pricing-table-id="prctbl_1QlJ8PG8f20oX1FlT8Dwt7XG" // Replace with your Stripe Pricing Table ID
                publishable-key="pk_live_51QfxL8G8f20oX1Fl25BnHzxvgGUtT603F5Uq2BUXepn2cEI16w5d3ziQO61VeR7AXMBGjOd4zPiAIF6bp5fxY3qU00ZOj0YxIZ"
                client-reference-id="user_12345" // Replace with your user's unique ID
            >
            </stripe-pricing-table>

            {/* Button to open Stripe Customer Portal */}
            <button
                onClick={handleOpenCustomerPortal}
                style={{
                    marginTop: '20px',
                    padding: '10px 20px',
                    backgroundColor: '#007bff',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer'
                }}
            >
                Manage My Subscription
            </button>
        </div>
    );
};

export default PricingPage;
