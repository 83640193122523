import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Login = () => {
    const navigate = useNavigate();
    const [rememberMe, setRememberMe] = useState(false);

    useEffect(() => {
        // Vérification des tokens
        const urlParams = new URLSearchParams(window.location.search);
        const githubToken = urlParams.get('github_token');
        const storedAccessToken = localStorage.getItem('access_token');

        if (githubToken) {
            // Enregistrement de github_token dans localStorage
            localStorage.setItem('github_token', githubToken);

            // Si l'access_token est déjà présent, redirige vers le dashboard
            if (storedAccessToken) {
                navigate('/dashboard');
            } else {
                // Sinon, redirige vers /auth pour obtenir l'access_token
                navigate('/auth');
            }
        } else if (!storedAccessToken) {
            // Redirection vers /auth si ni github_token ni access_token ne sont disponibles
            navigate('/auth');
        }
    }, [navigate]);

    const handleGitHubLogin = async () => {
        try {
            // Send the request to the backend with the Authorization header
            const response = await fetch(`${API_BASE_URL}/api/auth/github`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Include JWT token
                },
                redirect: 'follow', // Allow the browser to follow redirects
            });
    
            if (response.redirected) {
                // Redirect the user to the GitHub OAuth URL
                window.location.href = response.url;
            } else {
                const data = await response.json();
                console.error('GitHub OAuth failed:', data);
            }
        } catch (error) {
            console.error('Error during GitHub login:', error);
        }
    };
    

    const handleRememberMeChange = () => {
        setRememberMe(!rememberMe);
        if (!rememberMe) {
            localStorage.setItem('rememberMe', true);
        } else {
            localStorage.removeItem('rememberMe');
        }
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.header}>Your_AI_Developer</h1>
            <div style={styles.loginBox}>
                <button style={styles.githubButton} onClick={handleGitHubLogin}>
                    Login with GitHub
                </button>
                <div style={styles.rememberMeBox}>
                    <input
                        type="checkbox"
                        checked={rememberMe}
                        onChange={handleRememberMeChange}
                    />
                    <label style={styles.label}>Remember me</label>
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '50px',
        fontFamily: 'Arial, sans-serif',
    },
    header: {
        fontSize: '2rem',
        marginBottom: '20px',
    },
    loginBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '10px',
        width: '300px',
    },
    githubButton: {
        padding: '10px 20px',
        fontSize: '1.1rem',
        border: 'none',
        borderRadius: '5px',
        backgroundColor: '#333',
        color: '#fff',
        cursor: 'pointer',
        marginBottom: '20px',
    },
    rememberMeBox: {
        display: 'flex',
        alignItems: 'center',
    },
    label: {
        marginLeft: '10px',
    },
};

export default Login;
